<template>
    <v-card class="">
        <v-container class="pt-8 pb-10">
            <v-row justify="center">
                <v-col cols="11" class="px-0">
                    <div class="d-flex justify-space-between flex-wrap">
                        <h1 class="primary--text">
                            Create new rfp/reoi
                        </h1>
                    </div>
                    <hr class="hr-l mb-4 mt-2">
                </v-col>

                <v-stepper v-model="e1" elevation="0">
                    <v-stepper-header class="px-4 py-0 my-0">
                        <v-stepper-step :complete="e1 > 1" step="1">
                            <span class="text-subtitle-1">First step: Request informations</span>
                        </v-stepper-step>
                        <!-- <v-spacer></v-spacer> -->
                        <v-stepper-step :complete="e1 > 2" step="2" v-if="!isRequestOfExpression">
                            <span class="text-subtitle-1">Second step: Check recipients</span>
                        </v-stepper-step>
                    </v-stepper-header>
                    <v-stepper-items>
                        <v-stepper-content step="1">
                            <v-card-text class="text-h5 font-weight-bold">Complete request form</v-card-text>
                            <v-card-text>
                                <v-form>
                                    <p class="text-body-1">Select type of request</p>
                                    <v-progress-linear color="secondary" indeterminate reverse
                                        v-if="this.loading_rfp_list">
                                    </v-progress-linear>
                                    <v-radio-group v-model="rfptype" mandatory row class="pt-0 mt-1" v-else>
                                        <v-radio v-for="(item, i) in rfptypeitem" :value="item.id">
                                            <template v-slot:label>
                                                <div><span class="primary--text">{{ item.libele }}</span> <span
                                                        class="text-body-2">{{ item.designation }}</span></div>
                                            </template>
                                        </v-radio>
                                    </v-radio-group>
                                    <v-text-field v-model="rfptitle" label="Title" type="text" outlined dense
                                        :hide-details="rfptitleErrors.isvalid" :error-messages="rfptitleErrors.value"
                                        @input="$v.rfptitle.$touch()" @blur="$v.rfptitle.$touch()" class="mb-2"
                                        placeholder="Title">
                                    </v-text-field>
                                    <v-text-field v-model="rfpobject" label="Object" type="text" outlined dense
                                        :hide-details="rfpobjectErrors.isvalid" :error-messages="rfpobjectErrors.value"
                                        @input="$v.rfpobject.$touch()" @blur="$v.rfpobject.$touch()" class="mb-2"
                                        placeholder="Construction d'un centre hospitalier">
                                    </v-text-field>

                                    <label class="body-1">Proposal content body</label>
                                    <vue-editor v-model="rfpcontent" outlined dense
                                        :hide-details="rfpcontentErrors.isvalid"
                                        :error-messages="rfpcontentErrors.value" class="text-left mb-3" label="Body"
                                        placeholder="Lorem ipsum dolor sit amet. Non magnam asperiores in libero sint">
                                    </vue-editor>
                                </v-form>
                            </v-card-text>
                            <v-card-text>
                                <v-row class="d-flex flex-row flex-wrap">
                                    <v-btn @click="backToList" text class="subsecondary" color="secondary">
                                        <v-icon>{{ icons.mdiKeyboardBackspace }}</v-icon>
                                        <span>Exit</span>
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                    <v-col cols="12" md="4" class="">
                                        <v-btn block type="reset" color="error" outlined class="py-6"
                                            @click="resetFirstStape">
                                            Reset
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="12" md="4" class="">
                                        <v-btn block color="secondary" class="py-6"
                                            @click="submitCreateNewRequest">
                                            <!-- <v-btn block x-large color="secondary" class="py-6" @click="goToNextStep"> -->
                                            Next
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-stepper-content>

                        <v-stepper-content step="2">
                            <v-card-text>Select the recipients</v-card-text>
                            <select-recipientu :reqkey="this.rfp_keys" :type="'rfp'" class="my-4 "></select-recipientu>
                        </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>
            </v-row>
        </v-container>
    </v-card>

</template>

<script>
import DialogCheckInfos from '@/views/dashboard/components/DialogCheckInfos.vue'
import { mdiHelpCircleOutline } from '@mdi/js'
import { VueEditor } from "vue2-editor";
import { ref } from '@vue/composition-api'
import { Drequest } from '@/plugins/Drequest'
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, email } from 'vuelidate/lib/validators'
import SelectRecipientu from '@/views/dashboard/requests/components/SelectRecipientu.vue'

export default {
    mixins: [validationMixin],
    validations: {
        rfpobject: { required, minLength: minLength(5) },
        rfptitle: { required },
    },
    components: {
        SelectRecipientu,
        DialogCheckInfos,
        VueEditor
    },
    data() {
        return {
            e1: 1,
            loading: false,
            isRequestOfExpression: false,
            loading_rfp_list: true,
            requestlist: [],
            rfptypeitem: [],
            rfp_keys: -1
        }
    },

    beforeMount() {
        this.loading_rfp_list = true
        Drequest.api("lazyloading.requestype")
            .get((response) => {
                switch (response.success) {
                    case true:
                        response.listEntity.forEach((value, index) => {
                            if (value.libele === 'RFP' || value.libele === 'REOI') {
                                this.rfptypeitem.push({
                                    id: value.id,
                                    libele: value.libele,
                                    designation: value.designation
                                })
                            }
                        })
                        this.loading_rfp_list = false
                        break;
                    default:
                        this.$fire({
                            title: " Error occured !",
                            type: "error",
                            text: "Please reloadd the page..!",
                            timer: 3000
                        })
                        break;
                }
            })
            .catch((err) => {
                console.log("err")
                this.loading = false;
            })
        this.loading_rfp_list = false
    },
    computed: {
        rfptitleErrors() {
            const errors = { isvalid: true, value: [] }
            if (!this.$v.rfptitle.$dirty) return errors
            !this.$v.rfptitle.required && errors.value.push('Object is required.') && (errors.isvalid = false)
            return errors
        },
        rfpobjectErrors() {
            const errors = { isvalid: true, value: [] }
            if (!this.$v.rfpobject.$dirty) return errors
            !this.$v.rfpobject.minLength && errors.value.push('Object must be most long') && (errors.isvalid = false)
            !this.$v.rfpobject.required && errors.value.push('Object is required.') && (errors.isvalid = false)
            return errors
        },
        rfpcontentErrors() {
            const errors = { isvalid: true, value: [] }
            if (this.rfpcontent.length > 0) {
                errors.isvalid = true
            } else {
                errors.value.push('Request content musn\'t be null')
                errors.isvalid = false
            }
            return errors
        },
    },
    methods: {
        backToList() {
            this.$router.go(-1)
        },
        goToNextStep() {
            this.e1 = 2
        },
        goToPreviousStep() {
            this.e1 = 1
        },
        resetFirstStape() {
            this.$v.$reset()
            this.rfpobject = ''
            this.rfptitle = ''
            this.rfpcontent = ''
        },
        checkValidationForm() {
            if (this.rfpobjectErrors.isvalid && this.rfpcontentErrors.isvalid
                && this.rfptitleErrors.isvalid) {
                return true
            }
            else {
                return false
            }
        },

        submitCreateNewRequest() {
            var bodyFormData = {
                "rfp": {
                    "title": this.rfptitle,
                    "enterprise.id": this.$store.getters.getCompany.id,
                    "object": this.rfpobject,
                    "content": this.rfpcontent,
                    "requestype.id": this.rfptype
                }
            }

            this.$v.$touch()

            this.goToNextStep()
            if (this.checkValidationForm()) {
                this.loading = true;
                Drequest.api("create.rfp")
                    .data(bodyFormData)
                    .raw((response) => {
                        if (response.success) {
                            this.loading = false;
                            // this.rfp_keys = response.rfp.id
                            this.$store.commit("setTids", response.rfp.id);

                            this.$fire({
                                type: "success",
                                text: "Your RFP have been created.",
                                timer: 3000
                            })
                            this.goToNextStep()
                        }
                        else {
                            this.loading = false;

                            this.$fire({
                                type: "error",
                                text: "Uncount error..!",
                                timer: 3000
                            }).then(r => {
                                console.log(r.value);
                            });
                        }
                    })
                    .catch((err) => {
                        this.loading = false;
                        this.$fire({
                            type: "error",
                            text: "Error during connexion to the server..!",
                            timer: 3000
                        }).then(r => {
                            console.log('Error during connexion to the server..!');
                        });

                    })
            }
        },
    },
    setup() {

        const rfptype = ref('')
        const rfpobject = ref('')
        const rfptitle = ref('')
        const rfpcontent = ref('')
        const icons = { mdiHelpCircleOutline }

        return {
            icons,
            rfptype, rfpobject, rfptitle, rfpcontent,
        }
    },

}
</script>
<style lang="scss" scoped>
@import '@/styles/styles.scss';
</style>